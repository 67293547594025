import React, { useEffect, useReducer, useRef } from 'react';
import './styles.less';

import { urlForImage } from 'utils/uploads';
import { AdaptiveVideo } from 'components/Video';
import { TopNav } from './TopNav';
import { IconPlay, IconArrowDown, Player, Button } from 'components';
import ImageEvent from './ImageEvent';
import { Markdown } from 'components/Markdown';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

function isNonVideoEvent(event) {
  if (!event) return true;
  if (event?.streams.length === 0) return true;
  if (event?.streams[0].type === 'replay') return true;
  return false;
}

export const Event = ({ event, shallowEvent }) => {
  const eventPageRef = useRef();
  const [state, setState] = useLocalState({
    showSheet: false,
    showPlayer: null,
    loading: true,
  });

  useEffect(() => {
    if (state.loading) {
      setState({ loading: false, isNonVideoEvent: isNonVideoEvent(event) });
    }
  }, [event]);

  if (state.showPlayer && event) {
    return (
      <div className="event-page">
        <TopNav hasEvent />
        <Player
          event={event}
          onClose={() => setState({ showPlayer: false, loading: false })}
        />
      </div>
    );
  }

  function scrollDown() {
    eventPageRef.current.scrollTo({
      top: Number(eventPageRef.current.offsetHeight - 50),
      behavior: 'smooth',
    });
  }

  return (
    <div
      className={['event-page', state.isNonVideoEvent && 'typeImage']
        .filter(Boolean)
        .join(' ')}>
      <TopNav
        // possible fix for logo blank background
        key={!!event}
        hasEvent={!!event}
      />
      <div
        style={{
          display: 'none',
        }}>
        {event && !isNonVideoEvent && (
          <AdaptiveVideo
            video={event.streams.find((s) => s.type === 'main')?.video}
          />
        )}
      </div>
      <div id="player" />

      <div ref={eventPageRef} className="event-page__top">
        <div
          className="hero"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 92%),  url(${urlForImage(
              shallowEvent.coverImage ||
                shallowEvent.creatorAccount?.coverImage,
              { height: 800 * 1.5 }
            )})`,
            backgroundPosition: 'center',
          }}
        />
        <div className="content">
          <div className="creator">
            <img
              src={urlForImage(shallowEvent?.creatorAccount?.profileImage, {
                height: 120,
              })}
            />
            <div>{shallowEvent?.creatorAccount?.name}</div>
          </div>
          <h2>{shallowEvent.name}</h2>
          {!state.isNonVideoEvent && (
            <Markdown>{shallowEvent.description}</Markdown>
          )}
          {!state.loading && !state.isNonVideoEvent && (
            <Button
              primary
              fluid
              loading={state.loading}
              rounded
              icon
              onClick={() => {
                setState({ loading: !event, showPlayer: true });
              }}>
              <IconPlay />
              Watch Now
            </Button>
          )}
          {state.isNonVideoEvent && (
            <a className="icon-button" onClick={scrollDown}>
              <IconArrowDown />
            </a>
          )}
        </div>
        {state.isNonVideoEvent && event && <ImageEvent event={event} />}
      </div>
    </div>
  );
};
