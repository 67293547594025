import React, { useState, useContext, useEffect, useMemo } from 'react';
import './styles.less';

import { Select } from '../Select';
import {
  IconArrowBack,
  IconArrowForward,
  IconCart,
  IconCheckmark,
} from '../Icons';

import { Container } from '../Container';
import { Button } from '../Button';
import { FloatingCartButton } from '../FloatingCartButton';
import { CartContext } from 'contexts/cart';

import { useHistory } from 'react-router-dom';

import { urlForImage } from 'utils/uploads';
import { TrackingContext } from 'contexts/tracking';

import { getProductPrice } from 'utils/pricing';

import { sizes, sortSizes } from 'utils/sizing';

import { capitalize, sortBy } from 'lodash';

import { Markdown } from '../Markdown';

function getOptionValues(product, option, selection) {
  const values = {};

  product.subProducts.forEach((subProduct) => {
    const productOption = subProduct?.productOptions.find(
      (subProductOption) => subProductOption.item.name === option
    );
    if (!productOption) return;

    const matchSelection = Object.keys(selection).every((key) => {
      if (key === option) return true;

      return subProduct?.productOptions.find(
        ({ item }) => item.value === selection[key]
      );
    });
    const item = productOption.item;
    if (!matchSelection) return;

    if (!values[item.value]) {
      //if its a preOrder just allow the user to select the preOrder option regardless of stock
      values[item.value] = subProduct.preOrder ? 1 : subProduct.inventory;
    }
  });

  const sorted = sortBy(
    Object.keys(values).map((key) => {
      const value = values[key];
      let parsedValue = parseInt(key, 10);
      if (key === '00') {
        parsedValue = -1;
      }

      // If the key contains a 'Size' string them remove it.
      let theKey = key;
      if (key.includes('Size')) theKey = key.replace('Size', '').trim();

      return {
        label: theKey,
        sortValue: isNaN(parsedValue) ? theKey : parsedValue,
        disabled: !value,
        value: key, // Use real key here so that it matches the real value from db.
      };
    }),
    'sortValue'
  );

  const isSizing = sorted.every((item) => {
    return sizes.includes(item.label != null ? item.label : item.value);
  });

  if (isSizing) {
    return sorted.sort((a, b) => {
      const labelAExists = a.label != null;
      const labelBExists = b.label != null;

      // If the labels exist then sort by that.
      if (labelAExists && labelBExists) {
        return sortSizes(a.label, b.label);
      }

      // Otherwise sort by the value.
      return sortSizes(a.value, b.value);
    });
  }

  return sorted;
}

function getButtonType(hasProductInCart, product, selectedProduct) {
  if (hasProductInCart) {
    return 'added';
  }

  // XXX this looks a bit mess up
  if (product.type === 'base') {
    if (!selectedProduct) {
      return 'add-to-cart';
    } else if (!selectedProduct.inventory && product.preOrder) {
      return 'sold-out';
    } else if (!selectedProduct.inventory && !selectedProduct.preOrder) {
      return 'sold-out';
    } else return 'add-to-cart';
  }

  if (!product.inventory) {
    return 'sold-out';
  }

  if (!product.inventory && !product.preOrder) {
    return 'sold-out';
  }

  return 'add-to-cart';
}

const cache = {};

function isNonVideoEvent(event) {
  if (!event) return true;
  if (event?.streams.length === 0) return true;
  if (event?.streams[0].type === 'replay') return true;
  return false;
}

export const ProductOverview = ({
  event,
  product,
  header = true,
  onNavigate,
  pagePath,
}) => {
  const history = useHistory();
  const cart = useContext(CartContext);
  const tracking = useContext(TrackingContext);

  const { options = [] } = product;

  useEffect(() => {
    tracking.setLastProductId(product.id);
    tracking.addEvent(
      'product-viewed',
      {
        productId: product.id,
        eventId: event.id,
      },
      true
    );
  }, []);

  const [showDescription, toggleDescription] = useState(
    (product.description || '').length < 100
  );
  const [selection, setSelection] = useState(cache[product.id] || {});

  useEffect(() => {
    cache[product.id] = selection;
  }, [selection]);

  const selectedProduct = useMemo(() => {
    const selectionLength = Object.keys(selection).length;

    return (product.subProducts || []).find((product) => {
      if (product.productOptions.length !== selectionLength) {
        return false;
      }
      return product.productOptions.every(({ item }) => {
        return item.value === selection[item.name];
      });
    });
  }, [selection, product]);

  function addToCart() {
    const current = selectedProduct || product;
    tracking.addEvent('product-added-to-cart', {
      productId: current.id,
    });
    cart.addProduct({
      newProduct: current,
      parent:
        selectedProduct && product.id !== selectedProduct.id ? product : null,
      quantity: 1,
      eventId: event.id,
      discountId: current.discount?.id,
    });
  }

  const canCheckout = product.type == 'base' ? !!selectedProduct : true;

  const hasProductInCart = cart.products.find(({ product: cartProduct }) => {
    const current = selectedProduct || product;
    return current.id === cartProduct.id;
  });

  const buttonType = getButtonType(hasProductInCart, product, selectedProduct);

  const assets = (selectedProduct || product).assets || [];
  const currentProduct = selectedProduct || product;

  const showFloatingCart = isNonVideoEvent(event);
  const originalPrice = getProductPrice(currentProduct, true);
  const isDeposit = currentProduct.preOrder && originalPrice;
  const canShowInquiryLink = buttonType === 'sold-out' && !product.preOrder;

  return (
    <div className="product-overview">
      {header && (
        <Container className="product-overview__header">
          <IconArrowBack
            onClick={() => {
              if (history.length > 2 || document.referrer.length > 0) {
                history.goBack();
              } else {
                history.replace(`/event/${event.id}`);
              }
              return false;
            }}
          />
          <div className="spacer" />
        </Container>
      )}

      <div className="product-overview__content">
        <div className="product-overview__images">
          {assets
            .filter((c) => c.type == 'image')
            .map((assets) => {
              const url = urlForImage(assets, {
                height: 250 * 3,
              });
              return <img key={url} src={url} />;
            })}
        </div>
        <Container>
          {currentProduct?.preOrder?.message && (
            <div className="product-overview__preOrder">Pre Order</div>
          )}
          {currentProduct.parentName && (
            <div className="product-overview__title">
              {currentProduct.parentName}
            </div>
          )}
          <div className="product-overview__title">{currentProduct.name}</div>
          {!isDeposit && (
            <>
              {(currentProduct.discount || currentProduct.compareAtPrice) && (
                <div className="product-overview__price original">
                  {getProductPrice(currentProduct, true)}
                </div>
              )}
              {!originalPrice && (
                <div className="product-overview__price">
                  {getProductPrice(currentProduct)}{' '}
                </div>
              )}
            </>
          )}

          {isDeposit && (
            <div className="product-overview__price">
              {getProductPrice(currentProduct, true)}{' '}
              <span
                style={{
                  color: '#9b9b9b',
                }}>
                ({getProductPrice(currentProduct)} Deposit)
              </span>
            </div>
          )}

          <div
            key={showDescription} // This is a hack to force the markdown to re-render
            className={[
              'product-overview__description',
              showDescription ? 'show-all' : false,
            ]
              .filter(Boolean)
              .join(' ')}>
            <Markdown>{product.description}</Markdown>
          </div>
          {!showDescription && (
            <a className="readmore" onClick={() => toggleDescription(true)}>
              Read more
            </a>
          )}
          {product.type == 'base' && (
            <div>
              {options.map((option) => {
                return (
                  <Select
                    key={option}
                    value={selection[option]}
                    options={getOptionValues(product, option, selection)}
                    label={capitalize(option)}
                    ignoreDisabled
                    name={option}
                    onChange={({ name, value }) => {
                      setSelection({
                        ...selection,
                        [name]: value,
                      });
                    }}
                  />
                );
              })}
            </div>
          )}

          {buttonType === 'add-to-cart' && (
            <Button
              className="add-to-cart"
              disabled={!canCheckout}
              primary
              fluid
              onClick={addToCart}
              icon>
              <IconCart />
              Add To Cart
            </Button>
          )}

          {buttonType === 'added' && (
            <Button className="add-to-cart" disabled fluid icon>
              <IconCheckmark /> Added
            </Button>
          )}

          {canShowInquiryLink && <InquiryLink product={product} />}

          {product?.preOrder?.message && (
            <div className="product-overview__preOrderMessage">
              {product.preOrder.message}
            </div>
          )}

          {product.details && (
            <div
              className="product-overview__details-link"
              onClick={() =>
                onNavigate(`${pagePath}/details`, product.id, { product })
              }>
              Product Details <IconArrowForward />
            </div>
          )}

          {}
        </Container>
      </div>
      {showFloatingCart && <FloatingCartButton />}
    </div>
  );
};

const URL_REG = /^\[(.+)\]\((.+)\)$/m;

function InquiryLink(props) {
  const { product } = props;

  const data = parseUrl(product.description);

  if (!data) {
    return null;
  }

  const { label, url } = data;
  const tracking = useContext(TrackingContext);
  return (
    <Button
      className="add-to-cart"
      primary
      fluid
      icon
      onClick={() => {
        tracking.addEvent('product-sold-out', {
          productId: product.id,
        });
        window.location.href = url;
      }}>
      <IconCart />
      {label}
    </Button>
  );
}

function parseUrl(str) {
  const match = str.match(URL_REG);
  if (match) {
    const [, label, url] = match;
    return {
      url,
      label,
    };
  }
}
