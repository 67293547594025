import React from 'react';

import ReactMarkdown from 'react-markdown';
import { uriTransformer } from 'react-markdown';

export const Markdown = ({ children, ...props }) => (
  <div {...props} style={{ whiteSpace: 'pre-wrap' }}>
    <ReactMarkdown
      className="line-break"
      transformLinkUri={(url) => {
        if (url.startsWith('sms:')) {
          // Allow SMS links
          return url;
        } else {
          return uriTransformer(url);
        }
      }}>
      {children}
    </ReactMarkdown>
  </div>
);
